// import 'typeface-montserrat'
import './src/styles/code.css'
import './src/styles/global.css'

require("prismjs/themes/prism-tomorrow.min.css")

import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import Highlight, { defaultProps } from 'prism-react-renderer';
/* eslint-disable */
const component = {
    pre: props => {
        const className = props.children.props.className || '';
        const matches = className.match(/language-(?<lang>.*)/);
        return (
            <Highlight
                {...defaultProps}
                code={props.children.props.children}
                language={
                    matches && matches.groups && matches.groups.lang
                        ? matches.groups.lang
                        : ''
                }
            >
                {({ className, style, tokens, getLineProps, getTokenProps }) => (
                    <pre className={className} style={style}>
            {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                    {line.map((token, key, ) => {
                        if(
                            i === tokens.length - 1
                            && token.content === "\n"
                        ){
                            // remove empty line at the end
                            return null //<b>end</b>
                        }

                        return (
                            <span {...getTokenProps({token, key})} />
                        );
                    })}
                </div>
            ))}
          </pre>
                )}
            </Highlight>
        );
    },
};
export const wrapRootElement = ({ element }) => {
    return <MDXProvider components={component}>{element}</MDXProvider>;
};


function setSelectionCycles() {
    const highlightColors = [
         "#81c784" ,
         "#ffb74d" ,
         "#4dd0e1" ,
         "#bcaaa4" ,
         "#b9c077" ,
         "#fdd835" ,

    ];

    let clickCounter = 0;
    const root = document.querySelector(":root");

    document.addEventListener("mousedown", () => {
        const highlight = highlightColors[clickCounter++ % highlightColors.length];
        root.style.setProperty("--select-background", highlight);
    });
}
setSelectionCycles();

